//FULLHD и выше
.Footer {
  width: 100%;
  height: 430px;
  display: block;
  &__top {
    width: 100%;
    height: 350px;
    padding: 40px 60px;
    background-color: #f8f8f8;
    display: flex;
    align-items: start;
    justify-content: start;
    a,
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #505051;
      margin-bottom: 20px;
    }
    &__logo {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: start;
      img {
        width: auto;
        height: 80px;
        object-fit: contain;
      }
    }
    &__menu {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-self: center;
    }
    &__info {
      width: 30%;
      height: 100%;
      display: flex;
      flex-direction: column;
      &__socials {
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
          padding: 5px;
          margin: 10px 16px 0 -5px;
        }
      }
    }
  }
  &__down {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 60px;
    * {
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.02em;
      text-transform: capitalize;
      color: #a4a4a4;
      margin-right: 10px;
    }
    a {
      position: relative;
      padding-left: 10px;
    }
    a:before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #a4a4a4;
    }
    a:hover {
      color: #505051;
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Footer {
    &__top {
      &__logo {
        img {
          height: 70px;
        }
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Footer {
    height: 300px;
    &__top {
      height: 250px;
      padding: 30px 40px;
      a,
      p {
        font-size: 14px;
        margin-bottom: 16px;
      }
      &__logo {
        width: 50%;
        img {
          height: 70px;
        }
      }
      .Nav {
        width: 25%;
      }
      &__info {
        width: 25%;
        img {
          width: 28px;
          height: 28px;
          padding: 4px;
          margin: 0px 16px 0 -4px;
        }
      }
    }
    &__down {
      height: 50px;
      padding-left: 40px;
      * {
        font-size: 12px;
      }
      a:before {
        top: 5px;
        width: 4px;
        height: 4px;
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Footer {
    height: 250px;
    &__top {
      height: 210px;
      padding: 20px;
      &__logo {
        width: 40%;
        img {
          height: 50px;
        }
      }
      &__menu {
        width: 30%;
      }
      &__info {
        width: 30%;
      }
    }
    &__down {
      height: 40px;
      padding-left: 20px;
    }
  }
}

//480 - 768
@media screen and (max-width: 760px) {
  .Footer {
    height: fit-content;
    &__top {
      height: fit-content;
      padding: 40px 20px;
      flex-direction: column;
      row-gap: 30px;
      a,
      p {
        font-size: 14px;
        margin-bottom: 12px;
      }
      &__logo {
        justify-content: center;
        width: 100%;
        img {
          height: 40px;
        }
      }
      &__menu {
        width: 100%;
        text-align: center;
      }
      &__menu::before {
        content: '';
        width: 70px;
        height: 1px;
        background-color: #a4a4a4;
        margin: 0 auto 12px auto;
      }

      &__info {
        width: 100%;
        text-align: center;
        &__socials {
          margin-top: 30px;
          .instagramIcon {
            margin-right: 0;
          }
        }
        &__socials::before {
          content: '';
          width: 70px;
          height: 1px;
          background-color: #a4a4a4;
          margin: 0 auto 12px auto;
          display: block;
        }
      }
      &__info::before {
        content: '';
        width: 70px;
        height: 1px;
        background-color: #a4a4a4;
        margin: 0 auto 12px auto;
      }
    }
    &__down {
      height: fit-content;
      padding: 20px 40px;
      flex-direction: column;
      gap: 6px;
      align-items: center;
      justify-content: center;
      * {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 470px) {
  .Footer {
    &__top {
      padding: 40px 60px;
    }
    &__down {
      padding: 20px 12px;
      * {
        font-size: 10px;
      }
    }
  }
}
