//FULLHD и выше
.Contact {
  width: 100%;
  height: 100vh;
  padding: 200px 60px 0 180px;
  display: grid;
  grid-template-columns: auto 1fr 1100px;
  h2 {
    grid-column: 1 / 2;
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #ea3324;
  }
  &__form {
    grid-column: 3 / 4;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    &__inputs {
      width: 100%;
      padding: 40px 40px 76px 40px;
      height: fit-content;
      background: #f8f8f8;
      &__name {
        width: 100%;
        display: flex;
        column-gap: 20px;
        &__first {
          display: block;
          width: 50%;
        }
        &__last {
          display: block;
          width: 50%;
        }
      }
      label {
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #505051;
        padding-left: 10px;
        input {
          height: 64px;
          width: 100%;
          background: #ffffff;
          border: 1px solid #e1e1e1;
          border-radius: 2px;
          margin: 4px 0 24px 0;
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #505051;
          padding-left: 10px;
        }
        textarea {
          width: 100%;
          border: 1px solid #e1e1e1;
          border-radius: 2px;
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #505051;
          padding: 10px;
          margin-top: 4px;
        }
      }
      .checkboxWrapper {
        position: relative;
        input[type='checkbox'] {
          position: absolute;
          top: 10px;
          z-index: -1;
          opacity: 0;
          width: 20px;
          height: 20px;
        }
        .checkbox + label {
          display: inline-flex;
          align-items: center;
          user-select: none;
          margin-top: 10px;
          padding: 0;
        }
        .checkbox + label::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #a4a4a4;
          background-color: white;
          margin-right: 16px;
          padding-left: 20px;
          background-image: none;
        }
        .checkbox:checked + label::before {
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 50% 50%;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23505051' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
        }
        .checkboxLabel {
          font-weight: 400;
          font-size: 20px;
          line-height: 140%;
          letter-spacing: -0.02em;
          color: #a4a4a4;
        }
      }
    }
    button {
      background: #ea3324;
      border-radius: 2px;
      padding: 16px 80px;
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
      text-transform: capitalize;
      color: #ffffff;
      margin-top: 30px;
      align-self: end;
      width: fit-content;
    }
    button:hover {
      background: #cb2417;
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Contact {
    height: 200vh;
    padding: 220px 60px 0 60px;
    grid-template-columns: auto 1fr 950px;
    &__form {
      &__inputs {
        label {
          font-size: 18px;
          input {
            height: 56px;
            margin-bottom: 20px;
          }
        }
        .checkboxWrapper {
          .checkbox + label::before {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            padding-left: 16px;
          }
          .checkboxLabel {
            font-size: 18px;
          }
        }
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Contact {
    height: fit-content;
    padding: 160px 100px 150px 100px;
    display: grid;
    grid-template-rows: fit-content fit-content;
    grid-template-columns: 100%;
    gap: 20px;
    h2 {
      width: 100%;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      font-size: 30px;
      line-height: 110%;
    }
    &__form {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      width: 100%;
      height: 100%;
      &__inputs {
        padding: 50px 20px 80px 20px;
        height: fit-content;
        label {
          font-size: 16px;
          line-height: 130%;
          input {
            height: 40px;
            margin-bottom: 18px;
          }
        }
        .checkboxWrapper {
          .checkbox + label::before {
            width: 12px;
            height: 12px;
            padding-left: 12px;
          }
          .checkboxLabel {
            font-size: 12px;
            line-height: 130%;
          }
        }
      }
      button {
        padding: 8px 40px;
        font-size: 14px;
        margin-top: 16px;
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Contact {
    padding: 100px 20px 120px 20px;
  }
}

//480 - 768
@media screen and (max-width: 760px) {
  .Contact {
    padding: 100px 20px 100px 20px;
    h2 {
      font-size: 24px;
    }
    &__form {
      &__inputs {
        padding: 20px 12px;
        input {
          margin-bottom: 14px;
        }
        &__name {
          width: 100%;
          flex-direction: column;
          &__first {
            width: 100%;
          }
          &__last {
            width: 100%;
          }
        }
        .checkboxWrapper {
          .checkboxLabel {
            font-size: 10px;
          }
        }
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 470px) {
  .Contact {
    padding: 100px 12px 60px 12px;
    &__form {
      &__inputs {
        padding: 20px 8px 40px 8px;
      }
    }
  }
}
