//FULLHD и выше
.TermsPolicy {
  width: 100%;
  height: 100%;
  padding: 220px 60px 80px 60px;
  h1 {
    font-weight: 700 !important;
    font-size: 48px !important;
    line-height: 100% !important;
    letter-spacing: -0.02em !important;
    text-transform: uppercase !important;
    margin-bottom: 40px;
  }
  h2 {
    font-weight: 700 !important;
    font-size: 32px !important;
    line-height: 120% !important;
    letter-spacing: -0.02em !important;
    margin: 10px 0;
  }
  ul {
    padding-left: 30px;
  }
  p,
  li {
    font-weight: 400 !important;
    font-size: 24px !important;
    line-height: 130% !important;
    margin-bottom: 10px;
  }
  a {
    color: #ea3324 !important;
  }
}

//1440 - 1920
@media screen and (max-width: 1919px) {
  .TermsPolicy {
    padding: 200px 60px 80px 60px !important;
    h1 {
      font-size: 40px !important;
      line-height: 110% !important;
    }
    h2 {
      font-size: 28px !important;
    }
    ul {
      padding-left: 30px !important;
    }
    p,
    li {
      font-size: 22px !important;
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1439px) {
  .TermsPolicy {
    padding: 200px 40px 60px 40px !important;
    h1 {
      font-size: 30px !important;
    }
    h2 {
      font-size: 20px !important;
    }
    ul {
      padding-left: 30px !important;
    }
    p,
    li {
      font-size: 18px !important;
    }
  }
}

//320 - 1000
@media screen and (max-width: 1000px) {
  .TermsPolicy {
    padding: 100px 20px 50px 20px !important;
    h1 {
      font-size: 24px !important;
      margin-bottom: 20px;
    }
    h2 {
      font-size: 18px !important;
      margin-bottom: 10px;
    }
    ul {
      padding-left: 30px !important;
    }
    p,
    li {
      font-size: 16px !important;
    }
  }
}
