//FULLHD и выше
.Banner {
  width: 100%;
  height: 470px;
  background: #ea3324;
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding: 60px;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: 700;
    font-size: 64px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-align: center;
  }
  a {
    font-weight: 400;
    font-size: 28px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #ea3324;
    background: #ffffff;
    border-radius: 2px;
    padding: 16px 80px;
  }
  a:hover {
    background-color: #cb2417;
    color: #ffffff;
  }
}

//1440 - 1920
@media screen and (max-width: 1919px) {
  .Banner {
    height: 400px;
    h2 {
      font-size: 52px;
    }
    a {
      font-size: 24px;
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1439px) {
  .Banner {
    height: 280px;
    gap: 40px;
    padding: 40px;
    h2 {
      font-size: 36px;
    }
    a {
      padding: 8px 40px;
      font-size: 14px;
      line-height: 120%;
      letter-spacing: 0;
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Banner {
    height: 260px;
    gap: 30px;
    padding: 30px;
    h2 {
      font-size: 28px;
    }
  }
}

//480 - 768
@media screen and (max-width: 767px) {
  .Banner {
    height: 170px;
    gap: 24px;
    padding: 24px;
    h2 {
      font-size: 20px;
    }
  }
}
