//FULLHD и выше
.AdditionalServices {
  width: 100%;
  height: 100%;
  display: flex;
  img {
    width: 40%;
    height: auto;
    object-fit: cover;
  }
  &__text {
    width: 100%;
    height: 100%;
    padding: 160px 180px 160px 120px;
    display: block;
    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #ea3324;
      margin-bottom: 80px;
    }
    &__item {
      width: 100%;
      h4 {
        font-weight: 400;
        font-size: 34px;
        line-height: 140%;
        color: #ea3324;
        margin-bottom: 24px;
      }
      p {
        font-weight: 400;
        font-size: 34px;
        line-height: 140%;
        color: #505051;
      }
    }
    a {
      background: #ea3324;
      border-radius: 2px;
      padding: 16px 40px;
      font-weight: 400;
      font-size: 28px;
      line-height: 140%;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin: 40px 0 56px 0;
      display: block;
      width: fit-content;
    }
    a:hover {
      background: #cb2417;
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .AdditionalServices {
    &__text {
      padding: 60px;
      h2 {
        font-size: 40px;
        line-height: 110%;
        margin-bottom: 60px;
      }
      &__item {
        h4 {
          font-size: 30px;
          line-height: 130%;
        }
        p {
          font-size: 30px;
          line-height: 130%;
          width: 90%;
        }
      }
      a {
        margin: 30px 0 60px 0;
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .AdditionalServices {
    &__text {
      padding: 70px 100px 90px 40px;
      h2 {
        font-size: 30px;
        line-height: 110%;
      }
      &__item {
        h4 {
          font-size: 26px;
          margin-bottom: 14px;
        }
        p {
          font-size: 18px;
          width: 100%;
        }
      }
      a {
        padding: 8px 20px;
        font-size: 20px;
        margin: 30px 0 40px 0;
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .AdditionalServices {
    &__text {
      padding: 40px 20px;
      h2 {
        margin-bottom: 40px;
      }
    }
  }
}

//480 - 768
@media screen and (max-width: 760px) {
  .AdditionalServices {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      height: auto;
      max-height: 550px;
    }
    &__text {
      padding: 60px 50px 150px 50px;
      h2 {
        font-size: 24px;
        line-height: 110%;
        margin-bottom: 40px;
      }
      &__item {
        width: 100%;
        h4 {
          font-size: 24px;
          line-height: 110%;
          margin-bottom: 16px;
        }
        p {
          font-size: 16px;
          line-height: 130%;
        }
      }
      a {
        font-size: 18px;
        margin: 20px 0 40px 0;
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 470px) {
  .AdditionalServices {
    &__text {
      padding: 60px 12px 100px 12px;
      a {
        margin: 16px 0 20px 0;
      }
    }
  }
}
