//FULLHD и выше
.OurServices {
  width: 100%;
  height: 100%;
  padding: 200px 60px 150px 180px;
  display: flex;
  background: #f8f8f8;
  &__list_desktop {
    width: 25%;
    height: fit-content;
    display: block;
    margin-right: 50px;
    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      color: #ea3324;
      margin-bottom: 40px;
      padding-top: 16px;
    }
    &__items {
      width: 100%;
      height: 100%;
      list-style: none;
      li {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.02em;
        margin-bottom: 20px;
        cursor: pointer;
      }
      li:last-child {
        margin-bottom: 0;
      }
      li.active,
      li:hover {
        color: rgba(80, 80, 81, 0.5);
      }
    }
  }
  &__carousel_desktop {
    width: 75%;
    padding: 16px;
  }
  &__mobile {
    display: none;
  }
}
.ServicesCard {
  height: 650px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  img {
    width: 100%;
    height: 40%;
    max-height: 40%;
    min-height: 40%;
    object-fit: cover;
    border-radius: 8px;
  }
  &__text {
    width: 100%;
    height: 100%;
    padding: 28px 12px 56px 12px;
    h4 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #ea3324;
      margin-bottom: 28px;
    }
    p {
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
    }
  }
}
.ServicesCard.active {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .OurServices {
    padding: 170px 60px 80px 60px;
    &__list_desktop {
      h2 {
        font-size: 40px;
        line-height: 110%;
      }
      &__items {
        li {
          font-size: 22px;
        }
      }
    }
  }
  .ServicesCard {
    height: 600px;
    &__text {
      padding: 20px 12px 40px 12px;
      h4 {
        font-size: 28px;
        margin-bottom: 20px;
      }
      p {
        font-size: 22px;
        line-height: 130%;
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .OurServices {
    padding: 160px 40px 110px 40px;
    &__list_desktop {
      h2 {
        font-size: 30px;
      }
      &__items {
        li {
          font-size: 18px;
          margin-bottom: 16px;
        }
      }
    }
  }
  .ServicesCard {
    height: 500px;
    &__text {
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .OurServices {
    padding: 100px 20px 70px 20px;
    &__list_desktop {
      width: 30%;
      h2 {
        font-size: 30px;
        line-height: 110%;
        margin-bottom: 20px;
      }
    }
    &__carousel_desktop {
      width: 70%;
    }
  }
  .ServicesCard {
    height: 450px;
    &__text {
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 130%;
      }
    }
  }
}

//480 - 768
@media screen and (max-width: 760px) {
  .OurServices {
    padding: 100px 20px 40px 20px;
    &__list_desktop {
      display: none;
    }
    &__carousel_desktop {
      display: none;
    }
    &__mobile {
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #ea3324;
        margin-bottom: 40px;
      }
      display: flex;
      flex-direction: column;
      .accordion {
        .accordion__item {
          margin-bottom: 32px;
          .accordion__heading {
            font-size: 18px;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.02em;
            cursor: pointer;
          }
          .accordion__panel {
            p {
              font-size: 16px;
              line-height: 130%;
            }
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
              max-height: 250px;
              border-radius: 6px;
              margin: 8px 0;
            }
          }
        }

        .active {
          .accordion__heading .accordion__button {
            color: #ea3324 !important;
          }
        }
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 470px) {
  .OurServices {
    padding: 80px 12px 40px 12px;
    &__mobile {
      h2 {
        margin-bottom: 20px;
      }
      .accordion {
        .accordion__item {
          margin-bottom: 20px;
        }
      }
    }
  }
}
