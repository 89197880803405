//FULLHD и выше
.Header {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  &__logo {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 80px;
      object-fit: contain;
    }
  }
  &__menu {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bm-burger-button {
      display: none;
    }
    a {
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
      margin-right: 40px;
    }
    a:hover {
      color: #505051;
    }
    a.active {
      color: #ea3324;
    }
    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      background: #ea3324;
      border-radius: 2px;
      color: #fff;
    }
    &__button:hover {
      color: #fff !important;
      background: #cb2417;
    }
    .lang {
      width: fit-content;
      height: 100%;
      font-weight: 400;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.02em;
      background-color: transparent;
    }
    .lang:hover {
      color: #ea3324;
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Header {
    &__logo {
      img {
        height: 70px;
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Header {
    height: 70px;
    padding: 0px 40px;
    &__logo {
      img {
        height: 46px;
      }
    }
    &__menu {
      a {
        font-size: 14px;
        margin-right: 26px;
      }
      &__button {
        padding: 8px 20px;
      }
      .lang {
        font-size: 22px;
      }
    }
  }
}

//860 - 1000
@media screen and (max-width: 1000px) {
  .Header {
    padding: 0 20px;
    &__menu {
      a {
        margin-right: 20px;
      }
    }
  }
}

//768 - 860
@media screen and (max-width: 860px) {
  .Header {
    &__menu {
      &__button {
        max-width: min-content;
      }
    }
  }
}

//320 - 768
@media screen and (max-width: 768px) {
  .Header {
    height: 50px;
    padding: 10px 20px;
    box-shadow: none;
    &__logo {
      img {
        height: 30px;
      }
    }
    &__menu {
      a {
        font-size: 18px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #fff;
        margin-bottom: 40px;
      }
      a:hover {
        color: #e3adad;
      }
      a.active {
        color: #e3adad;
      }
      &__list {
        display: none;
      }
      &__button {
        display: none;
      }
      .lang {
        font-size: 20px;
        margin-right: 20px;
        letter-spacing: -0.02em;
      }
      .bm-burger-button {
        display: flex;
      }
      #react-burger-menu-btn {
        width: fit-content !important;
        right: 0 !important;
      }
      &__burger {
        &__list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: fit-content;
        }
        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 20px;
          border-radius: 2px;
          background: #fff;
          color: #ea3324 !important;
          width: fit-content;
        }
      }
    }
  }
}
