//FULLHD и выше
.Start {
  width: 100%;
  height: 100%;
  display: flex;
  img {
    width: 40%;
    height: auto;
    object-fit: cover;
  }
  &__text {
    background: #f8f8f8;
    width: 60%;
    padding: 144px 90px 144px 50px;
    display: grid;
    grid-template: min-content 10px min-content 1fr 20px min-content / 5% 35% 20% 10% 25%;
    h2 {
      grid-column: 1 / 6;
      grid-row: 1 / 2;
      font-weight: 700;
      font-size: 48px;
      line-height: 100%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      padding-bottom: 80px;
    }
    h6 {
      background: #ea3324;
      border-radius: 30px;
      padding: 10px 25px;
      gap: 10px;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      letter-spacing: -0.02em;
      color: #ffffff;
      width: fit-content;
      margin-bottom: 40px;
    }
    h4 {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      letter-spacing: -0.02em;
    }
    p {
      width: 100%;
      margin-top: 14px;
      font-weight: 400;
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.02em;
      span {
        a {
          color: #ea3324;
        }
      }
    }
    &__step1 {
      width: 100%;
      grid-column: 1 / 3;
      grid-row: 2 / 4;
      z-index: 2;
    }
    &__step2 {
      width: 100%;
      grid-column: 4 / 6;
      grid-row: 5 / 7;
      z-index: 2;
    }
    &__line {
      grid-column: 2 / 5;
      grid-row: 3 / 6;
      z-index: 1;
      &__wrapper {
        width: 100%;
        height: 100%;
        img {
          object-fit: fill;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Start {
    &__text {
      padding: 60px 110px 80px 60px;
      grid-template: min-content 10px min-content 1fr 10px min-content / 7% 38% 10% 10% 35%;
      h2 {
        font-size: 40px;
        line-height: 110%;
        padding-bottom: 72px;
      }
      h6 {
        font-size: 18px;
        margin-bottom: 40px;
      }
      h4 {
        font-size: 28px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Start {
    &__text {
      padding: 40px 70px 40px 40px;
      h2 {
        font-size: 30px;
        padding-bottom: 40px;
      }
      h6 {
        margin-bottom: 20px;
        border-radius: 20px;
        font-size: 14px;
      }
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 16px;
        line-height: 130%;
        margin-top: 10px;
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Start {
    flex-direction: column;
    img {
      width: 100%;
      height: auto;
      max-height: 550px;
    }
    &__text {
      padding: 50px 72px;
      grid-template: min-content 10px 20% 10px min-content / 10% 35% 10% 5% 40%;
      width: 100%;
      height: 500px;
      h2 {
        line-height: 110%;
      }
      &__step1 {
        grid-column: 1 / 3;
        grid-row: 2 / 6;
      }
      &__step2 {
        grid-column: 4 / 6;
        grid-row: 4 / 6;
      }
      &__line {
        grid-column: 2 / 5 !important;
        grid-row: 2 / 4 !important;
      }
    }
  }
}

//480 - 768
@media screen and (max-width: 760px) {
  .Start {
    img {
      max-height: 330px;
    }
    &__text {
      padding: 40px 20px;
      grid-template: min-content 20px min-content 20px min-content / 10% 1fr 5% 10% 1fr;
      height: fit-content;
      h2 {
        font-size: 24px;
      }
      &__step1 {
        grid-column: 1 / 4;
        grid-row: 2 / 4;
      }
      &__step2 {
        grid-column: 3 / 6;
        grid-row: 4 / 6;
      }
      &__line {
        grid-column: 2 / 5 !important;
        grid-row: 3 / 5 !important;
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 450px) {
  .Start {
    img {
      max-height: 220px;
    }
    &__text {
      padding: 40px 12px;
      grid-template: min-content 20px min-content 40px 20px min-content / 27% 1fr 27%;
      h6 {
        padding: 10px 35px;
      }
      &__step1 {
        grid-column: 1 / 3;
        grid-row: 2 / 4;
      }
      &__step2 {
        grid-column: 2 / 4;
        grid-row: 5 / 7;
      }
      &__line {
        grid-column: 1 / 4 !important;
        grid-row: 3 / 6 !important;
      }
    }
  }
}
