//FULLHD и выше
.Hero {
  width: 100%;
  height: 100%;
  margin-top: 280px;
  display: block;
  &__title {
    width: 100%;
    height: 100%;
    padding-left: 180px;
    h1 {
      font-weight: 800;
      font-size: 72px;
      line-height: 100%;
      letter-spacing: -0.02em;
      text-transform: uppercase;
      width: 100%;
      span {
        position: relative;
        max-width: 500px;
        img {
          position: absolute;
          height: 100px;
          width: 486px;
          top: -10%;
          right: -20%;
        }
      }
    }
  }

  &__content {
    width: 100%;
    height: fit-content;
    margin-top: 52px;
    display: grid;
    grid-template: 280px min-content / 40% 1fr;
    &__img {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
      margin-left: 180px;
      width: 100%;
      height: auto;
      max-height: 500px;
      z-index: 50;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__text {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      width: 100%;
      height: fit-content;
      background: #ea3324;
      padding: 0 180px;
      display: flex;
      column-gap: 100px;
      &__left {
        padding: 270px 0 100px 0;
        width: 50%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        p {
          margin-bottom: 16px;
        }
      }
      &__right {
        padding: 100px 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        a {
          padding: 16px 40px;
          background-color: #ffffff;
          border-radius: 2px;
          font-weight: 400;
          font-size: 28px;
          line-height: 140%;
          letter-spacing: -0.02em;
          text-transform: capitalize;
          color: #ea3324;
          margin: 16px 0 32px 0;
          width: fit-content;
        }
        a:hover {
          color: #ffffff;
          background-color: #cb2417;
        }
      }
      p {
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        color: #ffffff;
      }
      p:last-child {
        margin-bottom: 0px;
      }
      h6 {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #ffffff;
        margin-bottom: 16px;
      }
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Hero {
    margin-top: 240px;
    &__title {
      padding-left: 140px;
      h1 {
        font-size: 58px;
        span {
          max-width: 420px;
          img {
            height: 75px;
            width: 400px;
          }
        }
      }
    }

    &__content {
      margin-top: 40px;
      grid-template: 180px min-content / 40% 1fr;
      &__img {
        margin-left: 140px;
        max-height: 400px;
      }
      &__text {
        padding: 0 140px;
        column-gap: 70px;
        &__left {
          padding: 260px 0 74px 0;
        }
        &__right {
          padding: 74px 0;
          a {
            font-size: 24px;
          }
        }
        p {
          font-size: 22px;
          line-height: 130%;
        }
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Hero {
    margin-top: 160px;
    &__title {
      padding-left: 100px;
      h1 {
        font-size: 42px;
        span {
          max-width: 270px;
          img {
            height: 60px;
            width: 260px;
            top: -10%;
            right: -15%;
          }
        }
      }
    }

    &__content {
      margin-top: 30px;
      grid-template: 130px min-content / 40% 1fr;
      &__img {
        margin-left: 100px;
        max-height: 270px;
      }
      &__text {
        padding: 0 100px;
        column-gap: 40px;
        &__left {
          padding: 170px 0 60px 0;
        }
        &__right {
          padding: 60px 0;
          a {
            padding: 8px 20px;
            font-size: 20px;
          }
        }
        p {
          font-size: 18px;
        }
        h6 {
          font-size: 20px;
        }
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Hero {
    margin-top: 120px;
    &__title {
      padding: 0 20px 0 72px;
      h1 {
        font-size: 42px;
        span {
          max-width: 270px;
          display: inline-block;
          img {
            height: 50px;
            width: 256px;
            top: -5%;
            right: -10%;
          }
        }
      }
    }

    &__content {
      display: grid;
      grid-template: 100px min-content / 50% 1fr;
      &__img {
        margin-left: 72px;
        max-height: 250px;
      }
      &__text {
        padding: 0 72px;
        display: block;
        column-gap: 0px;
        background: #f8f8f8;
        &__left {
          padding: 170px 0 16px 0;
          width: 100%;
        }
        &__right {
          padding: 0px 0 72px 0;
          width: 100%;
          a {
            border: 0.7px solid #ea3324;
          }
        }
        p,
        h6 {
          color: #141414;
        }
      }
    }
  }
}

//480 - 768
@media screen and (max-width: 768px) {
  .Hero {
    margin-top: 80px;
    &__title {
      padding: 0 20px;
      h1 {
        font-size: 30px;
        line-height: 110%;
        span {
          max-width: 180px;
          margin-right: 10px;
          img {
            height: 42px;
            width: 160px;
            top: -20%;
            right: -10%;
          }
        }
      }
    }

    &__content {
      margin-top: 20px;
      grid-template: 90px min-content / 50% 1fr;
      &__img {
        margin-left: 20px;
        max-height: 240px;
      }
      &__text {
        padding: 0 20px;
        &__left {
          padding: 170px 0 20px 0;
        }
        &__right {
          padding: 0px 0 30px 0;
          a {
            font-size: 18px;
            margin: 16px 0;
          }
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 470px) {
  .Hero {
    &__title {
      padding: 0 12px;
      h1 {
        span {
          max-width: 190px;
          img {
            height: 48px;
            width: 170px;
            top: -25%;
            right: -10%;
          }
        }
      }
    }

    &__content {
      grid-template: 100px min-content / calc(100% - 24px);
      &__img {
        grid-column: 1 / 2;
        margin: 0 12px;
        width: 100%;
      }
      &__text {
        grid-column: 1 / 3;
        padding: 0 12px;
      }
    }
  }
}
