//FULLHD и выше
.Why {
  width: 100%;
  height: 100%;
  padding: 100px 230px 200px 180px;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
  }
  h4 {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.02em;
    width: 63%;
    margin: 16px 0 48px 0;
  }
  &__text {
    width: 90%;
    height: 100%;
    display: flex;
    gap: 50px;
    &__line {
      width: 380px;
      height: 4px;
      background: #ea3324;
      transform: translateY(15px);
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
        color: #505051;
        letter-spacing: -0.02em;
        margin-bottom: 10px;
        position: relative;
        padding-left: 30px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 12px;
          left: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #ea3324;
        }
      }
    }
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Why {
    padding: 100px 140px 130px 140px;
    h2 {
      font-size: 40px;
      line-height: 110%;
    }
    h4 {
      font-size: 28px;
      width: 73%;
      margin: 16px 0 30px 0;
    }
    &__text {
      &__line {
        width: 340px;
      }
      ul {
        li {
          font-size: 22px;
          &:before {
            top: 11px;
          }
        }
      }
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Why {
    padding: 60px 80px 120px 100px;
    h2 {
      font-size: 30px;
      line-height: 110%;
    }
    h4 {
      font-size: 20px;
      width: 60%;
    }
    &__text {
      gap: 25px;
      &__line {
        width: 230px;
        height: 3px;
        transform: translateY(10px);
      }
      ul {
        li {
          font-size: 18px;
          padding-left: 20px;
          letter-spacing: 0;
          &:before {
            top: 9px;
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Why {
    padding: 50px 72px;
    h4 {
      width: 80%;
      margin: 12px 0 16px 0;
    }
    &__text {
      gap: 10px;
      &__line {
        width: 70px;
        height: 4px;
        transform: translateY(22px);
      }
      ul {
        transform: translateY(12px);
      }
    }
  }
}

//480 - 768
@media screen and (max-width: 768px) {
  .Why {
    height: fit-content;
    padding: 40px 20px;
    h2 {
      font-size: 24px;
      width: 100%;
    }
    h4 {
      font-size: 18px;
      width: 100%;
      margin: 8px 0 20px 0;
    }
    &__text {
      &__line {
        width: 160px;
        height: 4px;
        transform: translateY(10px);
      }
      ul {
        transform: translateY(0px);
        li {
          font-size: 16px;
          padding-left: 15px;
        }
      }
    }
  }
}

//320 - 480
@media screen and (max-width: 470px) {
  .Why {
    padding: 40px 12px;
    h4 {
      margin: 8px 0 14px 0;
    }
    &__text {
      flex-direction: column;
      gap: 0px;
      &__line {
        transform: translateY(0px);
      }
      ul {
        transform: translateY(20px);
      }
    }
  }
}
