//FULLHD и выше
.Clients {
  width: 100%;
  height: 100%;
  padding: 200px 156px;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 100%;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    padding: 0 12px;
  }
  &__carousel {
    margin-top: 80px;
    padding: 12px;
    overflow: hidden;
  }
  .arrows {
    width: 100%;
    height: 100%;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .arrow {
      height: 56px;
      width: 56px;
      cursor: pointer;
      fill: #ebebeb !important;
    }
    .arrow:hover {
      fill: #ea3324 !important;
    }
    .disabled:hover {
      fill: #ebebeb !important;
      cursor: default;
    }
  }
}

.Card {
  width: 100%;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  padding: 30px 30px 35px 30px;
  display: flex;
  flex-direction: column;
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 140%;
    letter-spacing: -0.02em;
  }
  h6 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    letter-spacing: -0.02em;
    margin-top: 24px;
  }
  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #a4a4a4;
  }
}

//1440 - 1920
@media screen and (max-width: 1900px) {
  .Clients {
    padding: 120px 140px 200px 140px;
    h2 {
      font-size: 40px;
      line-height: 110%;
    }
    &__carousel {
      margin-top: 60px;
    }
    .arrows {
      margin-top: 40px;
    }
  }

  .Card {
    padding: 20px 20px 30px 20px;
    p {
      font-size: 18px;
    }
    h6 {
      font-size: 22px;
      margin-top: 20px;
    }
    span {
      font-size: 14px;
    }
  }
}

//1000 - 1440
@media screen and (max-width: 1400px) {
  .Clients {
    padding: 80px 100px 120px 100px;
    h2 {
      font-size: 30px;
    }
    &__carousel {
      margin-top: 32px;
    }
    .arrows {
      margin-top: 30px;
      gap: 12px;
      .arrow {
        width: 33px;
        height: 33px;
        svg {
          width: 33px;
          height: 33px;
        }
      }
    }
  }

  .Card {
    padding: 14px 14px 20px 14px;
    p {
      font-size: 16px;
      line-height: 130%;
    }
    h6 {
      font-size: 14px;
      margin-top: 14px;
    }
    span {
      font-size: 11px;
    }
  }
}

//768 - 1000
@media screen and (max-width: 1000px) {
  .Clients {
    padding: 70px 72px;
    h2 {
      line-height: 110%;
    }
    &__carousel {
      margin-top: 20px;
    }
    .arrows {
      margin-top: 20px;
    }
  }
}

//480 - 768
@media screen and (max-width: 768px) {
  .Clients {
    padding: 60px 20px;
    h2 {
      font-size: 24px;
    }
    .arrow {
      gap: 8px;
    }
  }
}

//320 - 480
@media screen and (max-width: 479px) {
  .Clients {
    padding: 40px 12px;
    h2 {
      padding: 0 12px;
      width: 60%;
    }
    .arrow {
      margin-top: 16px;
    }
  }
}
